/* Demo React */
/* Reset maison */
*{
    box-sizing: border-box;
    margin:0;
    padding:0;
    font-family: 'Open sans';
}
a{
    text-decoration: none;
    color: inherit;

}
li{
    list-style: none;
}

.progression{
    bottom: 0;
    right: 0;
    padding: 10px;
}
/* Structure -----------------------*/

.container{
    margin: 0 auto;
    max-width: 1024px;
}
.relative{
    display: relative;
}
.absolute{
    position: absolute;
}
.flex{
    display: flex;
}
.space-between{
    justify-content: space-between;
}
.wrapper{
min-height: 100vh;
}
/* Header */
header{
    padding: 1.5625%;
    border-bottom: solid 1px #ccc;
    box-shadow: 3px 3px 3px rgba(0,0,0,0.20);
    position: fixed;
    z-index: 9999;
    background: #fff;
    left: 0;
    right: 0;
    top: 0;
}

.logo{
    flex-grow: 4;
}
/* Navigation */
nav{
    flex-grow: 1;
}
nav ul{
    display: flex;
    position: relative;
}
nav li{
    padding: 0 3.125%;
    line-height: 100px;
    text-transform:uppercase ;
    flex-grow: 1;
    text-align: center;
    position: static;
    z-index: 1;
}
nav li:hover{
    border-bottom: solid 3px #888;
    font-weight: bold;
}
nav li.current{
    border-bottom: solid 3px #888;
    font-weight: bold;
}
.progressionBar{
    position: absolute;
    height: 3px;
    background: #ccc;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0;
    margin: 0;
    z-index: 0;
}
/* General */
body{
    background: url('../asset/presentation-back.jpg') no-repeat fixed;
    background-size: cover;
}
/* Sections */
section{
    min-height: 100vh;
    padding: 3.125% 1.5625%;
    overflow: hidden;
}

/* Accueil */
#accueil{
    padding:0;   
}
#accueil .container{
    overflow: hidden;
}

#accueil h2,
#accueil p,
#accueil button{
    position: relative;
}
.innerIntro{
    justify-content: flex-end;
}
.introduction{
    width:50%;
    padding: 0 3.125%;
    margin-top: 20%;
    position: static;
    z-index: 1;
}

#accueil{
    position:relative;
    min-height: 100vh;
}
.fondBlanc{
    position: absolute;
    right: 0;
    bottom: 0;
    top: 0;
    left: 50%;
    background:#fff;
    z-index: -2;
    box-shadow: -25vw 0 25vw rgb(0 0 0 / 25%);
}
#accueil .triangle{
    width: 0;
    height: 100vh;
    border-style: solid;
    border-width: 0px 25vw 100vh 0;
    border-color: transparent #fff transparent transparent;
    position: absolute;
    bottom: 0;
    right: 50%;
    z-index: -1;
}

#accueil h2{
    font-size: 2.1em;
    line-height: 1;
    margin-bottom: 1em;
}
#accueil p{
    line-height: 1.5;
    margin-bottom:2em;;
}
#accueil button{
    background: rgb(51,51,51);
    background: -moz-linear-gradient(126deg, rgba(51,51,51,1) 0%, rgba(0,0,0,1) 100%);
    background: -webkit-linear-gradient(126deg, rgba(51,51,51,1) 0%, rgba(0,0,0,1) 100%);
    background: linear-gradient(126deg, rgba(51,51,51,1) 0%, rgba(0,0,0,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#333333",endColorstr="#000000",GradientType=1);
    color:#fff;
    text-transform: uppercase;
    line-height: 40px;
    padding: 0 3.125%;
    border: none;
    border-radius: 3px;
    cursor: pointer;
    box-shadow: 3px 3px 3px rgba(0,0,0,0.25);
}
#accueil button:hover{
    filter: brightness(1.5);
}

.fondWrap{
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right:0;
    z-index: -1;
}

/* Presentation */
#presentation{
    padding-top: calc(100px + 6.25%);
    z-index: 1;
    position: relative;
}
#presentation h2{
    font-size: 2em;
    margin: 0 0 1em 0;
}
.specs{
    width:50%;
    padding: 3.125% 12.5% 3.125% 3.125%;
    position: relative;
}
.specs p{
    margin-bottom: 1em;
}
.listeTech{
    display:flex;
    flex-wrap: wrap;
}
.listeTech li{
    width: 50%;
    text-align: center;
    font-size: 2em;
    margin-bottom:30px;
    transform-origin: center;
}
.listeTech i{
    font-size: 4em;
}

.techWrap{
    width: 50%;
    padding:3.125%;
    background: rgb(22, 79, 136);
background: -moz-linear-gradient(126deg, rgba(22,79,136,1) 0%, rgba(13,61,109,1) 100%);
background: -webkit-linear-gradient(126deg, rgba(22,79,136,1) 0%, rgba(13,61,109,1) 100%);
background: linear-gradient(126deg, rgba(22,79,136,1) 0%, rgba(13,61,109,1) 100%);
filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#164f88",endColorstr="#0d3d6d",GradientType=1);
    color:#fff;
    border-radius: 3px;
    box-shadow: 3px 3px 3px rgba(0,0,0.25);
    z-index:-1;
    position: relative;
}
.fondSpecs{
   background-color: #fff;
   position: absolute;
   top: 0;
   left: 0;
   bottom: 0;
   width: 40vw;
   z-index: -1;
}

.triangleSpecsA{
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 100vh 0 0 20vw;
    border-color: transparent transparent transparent #fff;
    position:absolute;
    left:40vw;
    bottom:0;
    z-index: -1;
}


/* Contact */
#contact{
    min-height: initial;
    padding: 3.125% 0 0;
    position: relative;
    color:#fff;
}
#contact h2{
    font-size: 2.5em;
    margin: 1em 0;
    color: #333;
}

#contact > div{
    padding: 6.25% 3.125%
}

/*Formulaire de contact -------------*/
.formWrapper{
    justify-content: flex-end;
}
.formWrapper > div{
    width: 50%;
    padding: 3.125% 0 3.125% 3.125%;
    position: relative;
    z-index: 4;
}

#contact form{
    padding: 3.125%;
    background: #fff;
    color:#333;
    max-width: 580px;
}
.fieldLine{
    display: flex;
    justify-content: flex-start;
    padding: 1.5625% 3.125%;
}
.fieldLine  label{
    margin: 0 3.125% 0 0;
    display: inline-block;
    line-height: 36px;
    width: 25%;
    flex-grow: 1;
}
.fieldLine input, .fieldLine select, .fieldLine textarea{
    flex-grow: 1;
    width: 75%;
}
.fieldLine textarea{
    min-height: 150px;
}
.submitLine{
    display: flex;
    justify-content: flex-end;
    padding: 3.125%;
}
#submit{
    background: #000;
    color: #fff;
    line-height: 40px;
    padding: 0 3.125%;
    min-width: calc(100% / 3);
    text-transform: uppercase;
    border-style: none;
    border-radius: 3px;
    cursor: pointer;
    transition: background-color 0.3s;
}
#submit:disabled{
    background-color: #888;
}
#submit:hover{
    background-color: #2bc0db;
}
/* Block de fond du contact */

/* Crédit photo */
#contact .creditPhoto{
    padding: 3.125%;
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 2;
    background: #333;
    padding: 3.125%;
    background: rgb(22,79,136);
    background: linear-gradient(
126deg, rgba(22,79,136,1) 0%, rgba(13,61,109,1) 100%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#164f88",endColorstr="#0d3d6d",GradientType=1);
    color: #fff;
    border-radius: 3px;
    box-shadow: 3px 3px 3px rgb(0 0 0);
}
.creditWrapper{
    width: 50%;
    padding-right: 3.125%;
}
#contact .creditPhoto h3{
    font-size: 1.5em;
    margin-bottom: 1em;
}

.triangleFondCredit{
    position: absolute;
    right: 0;
    bottom: 100%;
    width: 0;
    left: 0;
height: 0;
border-style: solid;
border-width: 0 0 12.5vh 50vw;
border-color: transparent transparent #164f88 transparent;

}


/* Footer Navigation */
#footerNav{
    background: #222;
    color:#fff;
    padding: 3.125%;
    z-index: 5;
    position: relative;
    box-shadow: 0 -3px 3px rgba(0,0,0, 0.25);
}

#footerNav .flex{
    justify-content:space-between;
}
#footerNav h3{
    font-size: 1.5em;
    margin-bottom: 20px;
}



#footerNav ul{
    display: initial;
}
#footerNav li{
    list-style-type: none;
    margin: 0 0 10px 0;
    line-height: initial;
    text-align: left;
    font-size: 0.8em;
}
.footerLogo{
    text-align:center;
}
.fondContact{
    position: absolute;
    top:0;
    bottom: 0;
    left:0;
    right:0;
    z-index: 3;
}
.triangleContactA{
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 25vh 60vw 0 0;
    border-color: #fff transparent transparent transparent;
    position: absolute;
    top: 100%;
    left:0;
}
.triangleContactB{
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 0 3.125vh 50vw;
    border-color: transparent transparent #fff transparent;
    position: absolute;
    right: 0;
    bottom: 100%;
    left: 0;
}
.fondFormulaire{
    position: absolute;
    top: 15.625vh;
    right: 0;
    width: 50%;
    background: #fff;
    bottom: 0;
    z-index: 3;
}
/*Footer */ 
footer{
    background-color: #000;
    color:#fff;
}
footer p{
    font-size: 0.8em;
    line-height: 50px;
    text-align: center;
}

.messageModal{
    position: fixed;
    z-index: 9999;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.8);
}
.messageModal > div{
    position: absolute;
    width: 400px;
    height: 200px;
    left: calc(50% - 200px);
    top: calc(50% - 100px);
    background-color: #fff;
    padding: 3.125%;
    text-align: center;
    border-radius: 3px;
    animation: popUp 0.5s ease-out forwards;
    transform-origin: center;
    transform: scale(0);
}

@keyframes popUp {
    0%{
        transform: scale(0);
    }
    100%{
        transform: scale(1);
    }
    
}

.messageModal > div > p{
    position: absolute;
    height: 50px;
    top: calc(50% - 12.5px);
    left: 0;
    right: 0;
}
.close{
    position: absolute;
    top: 0;
    right: 0;
    padding: 3.125%;
    cursor: pointer;
}

/* Media Queries */

@media screen and (max-width: 960px){
    /* Accueil */
    #accueil h2{
        font-size: 1.75em;
    }
    .introduction{
        margin-top: calc(100% / 3);
    }
}
@media screen and (max-width: 500px){
    header{
        left:0;
        right: initial;
        bottom:0;
        width:100px;
    }
    header nav ul{
        flex-direction:column;
    }
    header  .container .flex{
        flex-direction: column;
        justify-content: start;
    }
    #accueil, #presentation, #contact{
        padding-left: calc(100px + 3.125%);
    }
    nav li {
        line-height: initial;
        margin: 10px 0;
        font-size: 0.7em;
    }
    .logo{
        text-align:center;
    }
    .logo img{
        width:50px;
        height: auto;
    }
    /* Accueil */
    #accueil h2{
        font-size: 1.5em;
    }
    .introduction{
        width: initial;
        margin-top: calc(100% * 2/3);
    }
    .fondBlanc {
        position: absolute;
        right: 0;
        bottom: 0;
        top: 16%;
        left: 0;
        background: #fff;
        z-index: 0;
        box-shadow: -25vw 0 25vw rgb(0 0 0 / 25%);
    }
    #accueil .triangle{
        display: none;
    }
}